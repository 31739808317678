<template>
  <div class="page">
    <div class="standalone-content">
      <h1>Terms of Use</h1>
      <router-link class="arrow" to="/">← <span>Back Home</span></router-link>
      <p>Last updated: Feb 20, 2021</p>
      <p>Please read these terms and conditions carefully before using Our Service.</p>
      <p>
        These terms of use outline the rules and regulations for the use of Tueday's Website, located at Tueday.com.
      </p>
      <p>
        By accessing this website we assume you accept these terms and conditions. Do not continue to use Tueday if you
        do not agree to take all of the terms and conditions stated on this page.
      </p>
      <h2>General Terms</h2>
      <ul>
        <li>
          Your private Notes will not be intentionally shown to other users or shared with third parties unless you
          explicitly choose to do so.
        </li>
        <li>
          Your personal data, including the content of your Notes, your email address (if provided) and identity will
          not be shared with anyone without your consent.
        </li>
        <li>
          You are responsible for the integrity and backing up of your data if you choose not to use our remote services
          to back it up.
        </li>
        <li>
          If the site ceases operation, you will receive an opportunity to download your stored data in a format that we
          deem suitable. (Likely Markdown or CSV).
        </li>
        <li>Any new features that affect privacy and your data security will be strictly opt-in.</li>
        <li>
          You can choose to remain anonymous and not sign up at all. If you do choose to sign up we may utilise a
          third-party Authentication system (i.e Google/Facebook Login). You may be bound to additional Terms and
          Conditions as result of using this third-party authentication system.
        </li>
      </ul>
      <h2>Cookies</h2>
      <p>
        Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are
        sent to your browser from the websites that you visit and are stored on your device's internal memory.
      </p>
      <p>
        Tueday does not use “cookies” explicitly. However, Tueday may use third party code and libraries that use
        “cookies” to collect information and improve their services.
      </p>
      <h2>Acceptable Use</h2>
      <p>
        You may not access or use the Site for any purpose other than that for which we make the site and our services
        available. The Site may not be used in connection with any commercial endeavors except those that are
        specifically endorsed or approved by us.
      </p>
      <p><strong>As a user of this Site, you agree not to:</strong></p>
      <ul>
        <li>
          Systematically retrieve data or other content from the Site to a compile database or directory without written
          permission from us
        </li>
        <li>
          Make any unauthorized use of the Site, including collecting usernames and/or email addresses of users to send
          unsolicited email or creating user accounts under false pretenses
        </li>
        <li>Use a buying agent or purchasing agent to make purchases on the Site</li>
        <li>Use the Site to advertise or sell goods and services</li>
        <li>
          Circumvent, disable, or otherwise interfere with security-related features of the Site, including features
          that prevent or restrict the use or copying of any content or enforce limitations on the use
        </li>
        <li>Engage in unauthorized framing of or linking to the Site</li>
        <li>
          Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account
          information such as user passwords
        </li>
        <li>Make improper use of our support services, or submit false reports of abuse or misconduct</li>
        <li>
          Engage in any automated use of the system, such as using scripts to send comments or messages, or using any
          data mining, robots, or similar data gathering and extraction tools
        </li>
        <li>
          Interfere with, disrupt, or create an undue burden on the Site or the networks and services connected to the
          Site
        </li>
        <li>Attempt to impersonate another user or person, or use the username of another user</li>
        <li>Sell or otherwise transfer your profile</li>
        <li>Use any information obtained from the Site in order to harass, abuse, or harm another person</li>
        <li>
          Use the Site or our content as part of any effort to compete with us or to create a revenue-generating
          endeavor or commercial enterprise
        </li>
        <li>
          Decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a
          part of the Site
        </li>
        <li>Attempt to access any portions of the Site that you are restricted from accessing</li>
        <li>Harass, annoy, intimidate, or threaten any of our employees, agents, or other users</li>
        <li>
          Copy or adapt the Site’s software, including but not limited to Flash, PHP, HTML, JavaScript, or other code
        </li>
        <li>
          Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material that
          interferes with any party’s uninterrupted use and enjoyment of the Site, or any material that acts as a
          passive or active information collection or transmission mechanism
        </li>
        <li>
          Use, launch, or engage in any automated use of the system, such as using scripts to send comments or messages,
          robots, scrapers, offline readers, or similar data gathering and extraction tools
        </li>
        <li>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site</li>
        <li>Use the Site in a manner inconsistent with any applicable laws or regulations</li>
        <li>Advertise products or services not intended by us</li>
      </ul>
      <h2>Our content</h2>
      <p>
        Unless otherwise indicated, the Site and Services including source code, databases, functionality, software,
        website designs, audio, video, text, photographs, and graphics on the Site (<strong>Our Content</strong>) are
        owned or licensed to us, and are protected by copyright and trade mark laws.
      </p>
      <p>
        Except as expressly provided in these Terms and Conditions, no part of the Site, Services or Our Content may be
        copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated,
        transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without
        our express prior written permission.
      </p>
      <p>
        You shall not (a) try to gain unauthorised access to the Site or any networks, servers or computer systems
        connected to the Site; and/or (b) make for any purpose including error correction, any modifications, adaptions,
        additions or enhancements to the Site or Our Content, including the modification of the paper or digital copies
        you may have downloaded.
      </p>
      <p>
        We shall (a) prepare the Site and Our Content with reasonable skill and care; and (b) use industry standard
        virus detection software to try to block the uploading of content to the Site that contains viruses.
      </p>
      <p>
        The content on the Site is provided for general information only. It is not intended to amount to advice on
        which you should rely. You must obtain professional or specialist advice before taking, or refraining from
        taking, any action on the basis of the content on the Site.
      </p>
      <p>
        Although we make reasonable efforts to update the information on our site, we make no representations,
        warranties or guarantees, whether express or implied, that Our Content on the Site is accurate, complete or up
        to date.
      </p>
      <h2>Your content</h2>
      <p>
        You Retain Ownership and Responsibility of Your Content. You retain ownership of all of your intellectual
        property rights in your Content. <strong>Content</strong> means software (including machine images), data, text,
        audio, video or images, and personal data. Tueday does not claim ownership over any of your Content. These Terms
        do not grant us any licenses or rights to your Content except for the limited rights needed for us to provide
        the Product to you, and as otherwise described in the Terms.
      </p>
      <p>
        You represent and warrant to us that: (a) you own or have the necessary licenses, rights, consents, or
        permissions to use or publish the Content that you include, submit, or use through our Product; and (b) none of
        your Content or your End-Users’ use of your Content or the Product will violate any applicable laws or the
        Acceptable Use Policy.
      </p>
      <p>
        Remember to Backup your content. You are responsible for maintaining, protecting, and making backups of your
        Content if you choose not to use our Backup Services. To the extent permitted by applicable law, Tueday will not
        be liable for any failure to store, or for loss or corruption of, your Content.
      </p>
      <p>
        There may be opportunities for you to choose to Share your Content with others. You understand and agree that if
        you decide to share your Content and make it publicaly available that it may be viewed by other users on the
        Site, and that they may be able to see who has posted that Content.
      </p>
      <p>
        We have the right to remove any Content you share publically on the Site if, in our opinion, such Content does
        not comply with the Acceptable Use Policy.
      </p>
      <p>
        We are not responsible and accept no liability for any Content including any such content that contains
        incorrect information or is defamatory or loss of Content. We accept no obligation to screen, edit or monitor
        any Content but we reserve the right to remove, screen and/or edit any Content without notice and at any time.
        Content has not been verified or approved by us and the views expressed by other users on the Site do not
        represent our views or values.
      </p>
      <p>
        If you wish to complain about Content uploaded by other users please contact us at
        <strong>hi@tueday.com</strong> and provide a link to the content in question.
      </p>
      <p></p>
      <p></p>
      <p></p>
      <h2>Changes to our Terms</h2>
      <p>
        We may update our Terms and Conditions from time to time. Thus, you are advised to review this page periodically
        for any changes. We will notify you of any changes by posting the new Terms and Conditions on this page.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions or suggestions about our Terms and Conditions, do not hesitate to contact us at
        <strong>hi@tueday.com</strong>.
      </p>
      <hr />
      <router-link class="arrow" to="/">← <span>Back Home</span></router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Terms',
};
</script>
